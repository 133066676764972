<template>
  <div>
    <b-card-actions title="Performance Management Evaluation" action-refresh>
      <b-card-body id="print">
        <div>
          <b-img
            alt="profile"
            :src="pmp_user.profile_photo_path"
            thumbnail
            left
            class="mr-1 mb-2"
            height="120"
            width="120"
          />
          <div class="d-flex justify-content-between">
            <div>
              <div style="line-height: 5px; margin-bottom: 2rem">
                <h3 class="c-primary bold">{{ pmp_user.name }}</h3>
                <h6>{{ pmp_user.job_level.name }}</h6>
              </div>
              <div style="line-height: 5px">
                <h6>Manager: {{ pmp_user.manager.name }}</h6>
                <h6>Evaluated By: {{ pmp_user.manager.name }}</h6>
              </div>
            </div>
            <div>
              <div style="line-height: 5px; margin-bottom: 5px">
                <h3 class="c-primary bold">FY 23/24 Year-End Review</h3>
                <h6>Organization: HR Academy</h6>
              </div>
              <div style="line-height: 5px">
                <h6>Location: {{ pmp_user.branch.name }}</h6>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="divider-pmp">
          <h4 class="c-warning bold">Overall</h4>
        </div>
        <div class="manager-overall">
          <div>
            <h5 class="c-primary bold">Manager Overall Evaluation</h5>
            <div class="mt-1 px-2">
              <table>
                <tr>
                  <th class="c-primary">Rating</th>
                  <td>: {{ score[pmp_overall.final_manager_score] }}</td>
                </tr>
                <tr>
                  <th class="c-primary" width="100px">Comment</th>
                  <td>: {{ pmp_overall.final_manager_comment }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="mt-2">
            <h5 class="c-primary bold">Employee Overall Evaluation</h5>
            <div class="mt-1 px-2">
              <table>
                <tr>
                  <th class="c-primary">Rating</th>
                  <td>: {{ score[pmp_overall.final_employee_score] }}</td>
                </tr>
                <tr>
                  <th class="c-primary" width="100px">Comment</th>
                  <td>: {{ pmp_overall.final_employee_comment }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="divider-pmp mt-2">
          <h4 class="c-warning bold">Goals</h4>
        </div>
        <div class="goals">
          <div v-for="goal in pmp_goals" :key="goal.id" class="mb-3">
            <h5 class="c-primary bold">{{ goal.goal.strategic_goals }}</h5>
            <p>{{ goal.goal.key_performance_indicator }}</p>
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="bold">Due Date: {{ goal.goal.due_date }}</h6>
              <h6 class="bold">
                Status:
                {{
                  goal.final_goal_status == 1 ? "Completed" : "Not Completed"
                }}
              </h6>
              <h6 class="bold">
                Completion Date: {{ toDate(goal.updated_at) }}
              </h6>
            </div>
            <div class="mt-1 px-2">
              <b-row>
                <b-col md="6">
                  <h6 class="bold border-b-1">Manager Evaluation</h6>
                  <table>
                    <tr>
                      <th>Rating</th>
                      <td>: {{ score[goal.final_manager_score] }}</td>
                    </tr>
                    <tr>
                      <th width="100px">Comment</th>
                      <td>: {{ goal.final_manager_comment }}</td>
                    </tr>
                  </table>
                </b-col>
                <b-col md="6">
                  <h6 class="bold border-b-1">Employee Evaluation</h6>
                  <table>
                    <tr>
                      <th>Rating</th>
                      <td>: {{ score[goal.final_employee_score] }}</td>
                    </tr>
                    <tr>
                      <th width="100px">Comment</th>
                      <td>: {{ goal.final_employee_comment }}</td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-card-body>

      <div class="text-right">
        <b-button variant="primary" size="sm" @click="exportPDF()">
          <feather-icon icon="file" /> Export as PDF
        </b-button>
      </div>
    </b-card-actions>
  </div>
</template>

<style>
.c-primary {
  color: #1a3a67;
}

.c-warning {
  color: #f37e20;
}

.divider-pmp {
  background: #f6f6f7;
  padding: 8px 10px 0px 10px;
  border: solid 1px #ececec;
  margin-bottom: 1rem;
}

.border-b-1 {
  border-bottom: 2px solid #ececec;
}

.manager-overall h5 {
  border-bottom: 1px solid #1a3a67;
}
</style>

<script>
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      pmp_overall: {},
      pmp_user: {},
      pmp_goals: [],
      score: [
        "0 - Has not met Objectives",
        "1 - Met some Objectives but not all",
        "2 - Met Objectives",
        "3 - Exceeded some Objectives & met others",
        "4 - Exceeded all Objectives",
      ],
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    toDate(str) {
      return moment(str).format("DD/MM/YYYY");
    },

    fetchData() {
      this.callApi({
        url: "users/fetch",
        success: (res) => {
          this.pmp_user = res.result;
        },
      });

      this.callApi({
        url: "final_evaluations/fetch",
        params: {
          user_id: this.hashid(this.getAuthId()),
          detail: true,
        },
        success: (res) => {
          this.pmp_overall = res.result[0];

          res.result.map((item) => {
            this.pmp_goals.push(item);
          });
        },
      });
    },

    exportPDF() {
      const doc = new jsPDF("p", "mm", "a4");
      const width = doc.internal.pageSize.getWidth();
      const height = doc.internal.pageSize.getHeight();

      html2canvas(document.querySelector("#print")).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        position -= pageHeight;

        while (position > -((canvas.height * imgWidth) / canvas.width)) {
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          position -= pageHeight;
        }

        doc.save("PMP.pdf");
      });
    },
  },
};
</script>